export const links = [
    {
        id:1,
        text:'Process-X',
        url:'/'
    },
    {
        id:2,
        text:'live',
        url:'/live'
    },
    {
        id:3,
        text:'reports',
        url:'/reports'
    },
]

// base url 
// issues with http > https
// export const URL = 'http://127.0.0.1:8000/api/v2';
export const URL = 'https://factorynext-back-stag.arthor.com.bd';

export const URL_FOR_PROTECTED_APIs = `${URL}`;

export const intervalTime = 10000;

export const graphCount = [
    {
        id:1,
        root:'curing',
        noGrpah:2,
        graphs:[
            {
                id:1,
                title:'Temperature',
                dataKey:'presentTemperature'
            },
            {
                id:2,
                title:'Humidity',
                dataKey:'presentHumidity'
            },
        ]
    },
    {
        id:2,
        root:'etp',
        noGrpah:5,
        graphs:[
            {
                id:1,
                title:'Inlet Flow',
                dataKey:'inletFlow'
            },
            {
                id:2,
                title:'Inlet pH',
                dataKey:'inletPH'
            },
            {
                id:3,
                title:'Outlet Flow',
                dataKey:'outletFlow'
            },
            {
                id:4,
                title:'Outlet pH',
                dataKey:'outletPH'
            },
            {
                id:5,
                title:'Conductivity',
                dataKey:'Conductivity:'
            },
        ]
    },
    {
        id:3,
        root:'pdo',
        noGrpah:2,
        graphs:[
            {
                id:1,
                title:'Temperature PV',
                dataKey:'presentTemperature'
            },
            {
                id:2,
                title:'Temperature SV',
                dataKey:'setTemperature'
            },
        ]
    },
    {
        id:4,
        root:'pastemixing',
        noGrpah:4,
        graphs:[
            {
                id:1,
                title:'Oxide Weight',
                dataKey:'oxideWeight'
            },
            {
                id:2,
                title:'DM Water Weight',
                dataKey:'dmWaterWeight'
            },
            {
                id:3,
                title:'Acid Weight',
                dataKey:'acidWeight'
            },
            {
                id:4,
                title:'Temperature',
                dataKey:'temperature'
            },
        ]
    },
    {
        id:5,
        root:'energy',
        noGrpah:10,
        graphs:[
            {
                id:1,
                title:'Consumption',
                dataKey:'totalConsumption'
            },
            {
                id:2,
                title:'Real Power',
                dataKey:'activePower'
            },
            {
                id:3,
                title:'Reactive Power',
                dataKey:'reactivePower'
            },
            {
                id:4,
                title:'Voltage 1',
                dataKey:'voltage1'
            },
            {
                id:5,
                title:'Voltage 2',
                dataKey:'voltage2'
            },
            {
                id:6,
                title:'Voltage 3',
                dataKey:'voltage3'
            },
            {
                id:7,
                title:'Current 1',
                dataKey:'current1'
            },
            {
                id:8,
                title:'Current 2',
                dataKey:'current2'
            },
            {
                id:9,
                title:'Current 3',
                dataKey:'current3'
            },
            {
                id:10,
                title:'Power Factor',
                dataKey:'powerFactor'
            },
           
        ]
    },
]